import Auth from '@/store/auth'
import Awards from '@/store/awards'
import AccountCreation from '@/store/account-creation'
import EngagementCampaigns from '@/store/engagement/campaigns'
import Personalization from '@/store/engagement/personalization'
import CoinDistribution from '@/store/engagement/distribution'
import ClearStore from '@/store/clear-store'
import User from '@/store/user'
import Report from '@/store/report'
import Config from '@/store/config'
import Company from '@/store/company'
import Partner from '@/store/partner'
import Address from '@/store/address'
import Feedback from '@/store/feedback'
import Category from '@/store/category'
import Campaign from '@/store/campaign'
import Breadcrumb from '@/store/breadcrumb'
import UserImport from '@/store/user-import'
import Medias from '@/store/medias'
import Notifications from '@/store/notifications'
import Dash from '@/store/dash'
import DashProducts from '@/store/dash/products'

export default {
  'engagement-campaigns': EngagementCampaigns,
  'engagement-personalization': Personalization,
  'engagement-distribution': CoinDistribution,
  awards: Awards,
  'account-creation': AccountCreation,
  'user-import': UserImport,
  breadcrumb: Breadcrumb,
  feedback: Feedback,
  category: Category,
  campaign: Campaign,
  company: Company,
  dash: Dash,
  'dash-products': DashProducts,
  address: Address,
  partner: Partner,
  config: Config,
  report: Report,
  user: User,
  auth: Auth,
  'clear-store': ClearStore,
  medias: Medias,
  notifications: Notifications
}
