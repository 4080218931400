import { Factory } from 'miragejs'
import { faker } from '@faker-js/faker'
import { datetimeToUnixEpoch, getRandomBoolean } from '../utils/index'

const randomBoolean = getRandomBoolean()

export default {
  vidalinkGraph: Factory.extend({
    tabs () {
      return [
        {
          label: 'Ativações do cartão',
          icon: 'info-information-circle',
          chart_title: 'Ativações do cartão'
        }
      ]
    },
    names () {
      return {
        data1: 'Ativações'
      }
    },
    columns () {
      const fakeArray1 = faker.helpers.arrayElements([60, 90, 120, 125, 140, 150, 155, 160, 200, 240, 250, 260, 280, 300, 340], { min: 2, max: 44 })
      const fakeArray2 = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set']
      return [
        [
          'x',
          ...fakeArray2.slice(0, fakeArray1.length)
        ],
        ['data1', ...fakeArray1]
      ]
    }
  }),
  engagementTable: Factory.extend({
    tabs () {
      return [
        {
          chart_title: 'Resgatados em ofertas no período',
          label: 'Distribuídos no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        },
        {
          chart_title: 'Resgatados em saques no período',
          label: 'Resgatados no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        }
      ]
    },
    names () {
      return {
        data1: 'Período'
      }
    },
    data () {
      const howManyItems = faker.number.int({ min: 0, max: 100 })
      const initialData = []

      for (let index = 0; index < howManyItems; index++) {
        initialData.push({
          campaign_name: faker.word.words(faker.number.int({ min: 1, max: 6 })),
          partner_name: faker.word.words(faker.number.int({ min: 1, max: 4 })),
          total_redemptions: faker.number.int({ min: 0, max: 500 }),
          HasVoucher: faker.datatype.boolean()
        })
      }
      return initialData
    }
  }),
  engagementGraph: Factory.extend({
    tabs () {
      return [
        {
          chart_title: 'Distribuídos no período',
          label: 'Distribuídos no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        },
        {
          chart_title: 'Resgatados no período',
          label: 'Resgatados no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        }
      ]
    },
    names () {
      return {
        data1: 'Período'
      }
    },
    columns () {
      const fakeArray1 = faker.helpers.arrayElements([60, 90, 120, 125, 140, 150, 155, 160, 200, 240, 250, 260, 280, 300, 340], { min: 2, max: 12 })
      const fakeArray2 = faker.helpers.arrayElements([60, 90, 120, 125, 140, 150, 155, 160, 200, 240, 250, 260, 280, 300, 340], { min: 2, max: 12 })
      return [
        [
          ['Distribuído', ...fakeArray1],
          ['Resgatado', ...fakeArray2]
        ],
        [
          ['Distribuído', ...fakeArray2],
          ['Resgatado', ...fakeArray1]
        ]
      ]
    }
  }),
  awardTable: Factory.extend({
    tabs () {
      return [
        {
          chart_title: 'Resgatados em ofertas no período',
          label: 'Distribuídos no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        },
        {
          chart_title: 'Resgatados em saques no período',
          label: 'Resgatados no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        }
      ]
    },
    names () {
      return {
        data1: 'Período'
      }
    },
    data () {
      const howManyItems = faker.number.int({ min: 0, max: 100 })
      const initialData = []

      for (let index = 0; index < howManyItems; index++) {
        initialData.push({
          campaign_name: faker.word.words(faker.number.int({ min: 1, max: 6 })),
          partner_name: faker.word.words(faker.number.int({ min: 1, max: 4 })),
          total_redemptions: faker.number.int({ min: 0, max: 500 }),
          HasVoucher: faker.datatype.boolean()
        })
      }
      return initialData
    }
  }),
  awardGraph: Factory.extend({
    tabs () {
      return [
        {
          chart_title: 'Distribuídos no período',
          label: 'Distribuídos no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        },
        {
          chart_title: 'Resgatados no período',
          label: 'Resgatados no período',
          icon: 'info-information-circle',
          total: `${faker.number.int({ min: 0, max: 2000 })}`
        }
      ]
    },
    names () {
      return {
        data1: 'Período'
      }
    },
    columns () {
      const fakeArray1 = faker.helpers.arrayElements([60, 90, 120, 125, 140, 150, 155, 160, 200, 240, 250, 260, 280, 300, 340], { min: 2, max: 12 })
      const fakeArray2 = faker.helpers.arrayElements([60, 90, 120, 125, 140, 150, 155, 160, 200, 240, 250, 260, 280, 300, 340], { min: 2, max: 12 })
      return [
        [
          ['Distribuído', ...fakeArray1],
          ['Resgatado', ...fakeArray2]
        ],
        [
          ['Distribuído', ...fakeArray2],
          ['Resgatado', ...fakeArray1]
        ]
      ]
    }
  }),
  balance: Factory.extend({
    balance () {
      return faker.number.int({ min: 0, max: 2000 })
    }
  }),
  // era pra ser awardData, award_data, porém essas variações estão dando erro na hora de criar o seed
  award: Factory.extend({
    total_orders () {
      return faker.number.int({ min: 0, max: 2000 })
    },
    awarded_users () {
      return faker.number.int({ min: 0, max: 1000 })
    },
    redemption_rate () {
      return [faker.number.int({ min: 0, max: 100 })]
    }
  }),
  engagement: Factory.extend({
    total_orders () {
      return faker.number.int({ min: 0, max: 2000 })
    },
    awarded_users () {
      return faker.number.int({ min: 0, max: 1000 })
    },
    redemption_rate () {
      return [faker.number.int({ min: 0, max: 100 })]
    }
  }),
  vidalink: Factory.extend({
    total_cards_activated () {
      return faker.number.int({ min: 0, max: 2000 })
    },
    period_cards_activated () {
      return faker.number.int({ min: 0, max: 1000 })
    },
    activation_rate () {
      return [faker.number.int({ min: 0, max: 100 })]
    }
  }),
  notification: Factory.extend({
    id () {
      return faker.string.uuid()
    },
    name () {
      return faker.company.buzzPhrase()
    },
    title () {
      return faker.company.buzzPhrase()
    },
    image () {
      return faker.image.urlLoremFlickr({ category: 'abstract', width: 423, height: 270 })
    },
    status () {
      return faker.number.int({ max: 1 })
    },
    clicks () {
      return faker.number.int({ max: 5000 })
    },
    message () {
      return faker.lorem.words(5)
    },
    updated_at () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    created_at () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    start_date () {
      return datetimeToUnixEpoch(faker.date.past())
    },
    end_date () {
      return datetimeToUnixEpoch(faker.date.future())
    },
    partner () {
      return randomBoolean
        ? {
          id: faker.string.uuid(),
          name: faker.company.name()
        }
        : null
    },
    campaign () {
      return randomBoolean
        ? {
          id: faker.string.uuid(),
          name: faker.company.name()
        }
        : null
    }
  })
}
