import * as types from '@/store/types'
import rest from '@/modules/rest'
import { FEEDBACK } from '@/store/types'

const state = {
  notification: {},
  notifications: [],
  notification_creation_step: 1
}

const getters = {
  [types.NOTIFICATION_CREATION_STEP]: ({ notification_creation_step }) => notification_creation_step,
  [types.NOTIFICATION]: ({ notification }) => notification,
  [types.NOTIFICATIONS]: ({ notifications }) => notifications
}

const mutations = {
  [types.NOTIFICATION_CREATION_STEP]: (state, payload) => {
    state.notification_creation_step = payload
  },
  [types.NOTIFICATION]: (state, payload) => {
    state.notification = payload
  },
  [types.NOTIFICATIONS]: (state, payload) => {
    state.notifications = payload
  }
}

const actions = {
  [types.GET_NOTIFICATION_DETAILS]: async ({ dispatch, commit }, payload) => {
    const id = payload

    try {
      const notificationDetails = await rest().get(`/notice/${id}`)
      commit(types.NOTIFICATION, notificationDetails.data.data)
      return true
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao carregar informações da notificação',
        text: 'Tente novamente mais tarde'
      }
      dispatch(types.FEEDBACK, feedback)
      return false
    }
  },
  [types.GET_NOTIFICATIONS]: async ({ commit }, variables) => {
    const notifications = await rest().get('/notice', {
      params: {
        ...variables
      }
    })
    commit(types.NOTIFICATIONS, notifications.data)
  },
  [types.CREATE_NOTIFICATION]: async ({ dispatch }, payload) => {
    const formData = new FormData()

    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))
    try {
      const res = await rest().post('/notice', formData)
      if (res && res.data && res.data.success) {
        const feedback = {
          type: 'success',
          title: 'Notificação criada com sucesso',
          text: ''
        }
        dispatch(FEEDBACK, feedback)
      } else {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao criar a sua notificação',
          text: 'Tente novamente mais tarde'
        }
        dispatch(FEEDBACK, feedback)
      }
      return res.data.success
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao criar a sua notificação',
        text: 'Tente novamente mais tarde'
      }
      dispatch(FEEDBACK, feedback)
      return false
    }
  },
  [types.UPDATE_NOTIFICATION]: async ({ dispatch }, payload) => {
    const formData = new FormData()

    Object.keys(payload)
      .filter(key => (typeof payload[key] !== 'undefined') && (typeof payload[key] !== 'object' || !!payload[key]))
      .forEach(key => formData.append(key, payload[key]))
    try {
      const res = await rest().patch(`/notice/${payload.id}`, formData)
      if (res && res.data && res.data.success) {
        const feedback = {
          type: 'success',
          title: 'Notificação editada com sucesso',
          text: ''
        }
        dispatch(FEEDBACK, feedback)
      } else {
        const feedback = {
          type: 'error',
          title: 'Houve um erro ao editar a sua notificação',
          text: 'Tente novamente mais tarde'
        }
        dispatch(FEEDBACK, feedback)
      }
      return res.data.success
    } catch (e) {
      const feedback = {
        type: 'error',
        title: 'Houve um erro ao editar a sua notificação',
        text: 'Tente novamente mais tarde'
      }
      dispatch(FEEDBACK, feedback)
      return false
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
