import { getRandomBoolean } from '../utils/index'

const randomBoolean = getRandomBoolean()

export default function seeds (server) {
  server.createList('notification', randomBoolean ? 5 : 0)
  server.create('award')
  server.create('engagement')
  server.create('vidalink')
  server.create('balance')
  server.create('awardGraph')
  server.create('awardTable')
  server.create('engagementGraph')
  server.create('engagementTable')
  server.create('vidalinkGraph')
  // server.createList('award_data', 10)
}
