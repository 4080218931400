/**
 * Tipos da Store de Autenticação.
 */

export const AUTH = 'auth/AUTH'
export const AUTH_LOGIN = 'auth/AUTH_LOGIN'
export const AUTH_CHECK = 'auth/AUTH_CHECK'
export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT'
export const AUTH_DECODE = 'auth/AUTH_DECODE'
export const AUTH_REMEMBER = 'auth/AUTH_REMEMBER'
export const AUTH_CREDENTIALS = 'auth/AUTH_CREDENTIALS'
export const AUTH_CHECK_TOKEN = 'auth/AUTH_CHECK_TOKEN'
export const AUTH_LOGIN_COMPANY = 'auth/AUTH_LOGIN_COMPANY'
export const AUTH_RESET_PASSWORD = 'auth/AUTH_RESET_PASSWORD'

export const AUTH_ACTIVATE = 'auth/AUTH_ACTIVATE'
export const AUTH_ACTIVATE_CONFIRM = 'auth/AUTH_ACTIVATE_CONFIRM'
export const AUTH_ACTIVATE_COMPANY = 'auth/AUTH_ACTIVATE_COMPANY'

export const AUTH_ACCESS_CLUB = 'auth/AUTH_ACCESS_CLUB'

export const GET_USER_ID = 'auth/GET_USER_ID'

export const CHANGE_ACCOUNT = 'auth/CHANGE_ACCOUNT'
export const CHANGE_TO_COMPANY = 'auth/CHANGE_TO_COMPANY'

/**
 * Tipos da Store de Criação de Conta.
 */

export const ACCOUNT_CREATE = 'account-creation/ACCOUNT_CREATE'

/**
 * Tipos da Store de Configuração.
 */

export const CONFIG_USER = 'config/CONFIG_USER'
export const CONFIG_RESET = 'config/CONFIG_RESET'
export const CONFIG_COMPANY_ID = 'config/CONFIG_COMPANY_ID'
export const CONFIG_PERMISSIONS = 'config/CONFIG_PERMISSIONS'
export const CONFIG_CLEAR_STORE = 'config/CONFIG_CLEAR_STORE'
export const CONFIG_COMPANY_LIST = 'config/CONFIG_COMPANY_LIST'
export const CONFIG_PERSONALIZATION = 'config/CONFIG_PERSONALIZATION'
export const CONFIG_COMPANIES_ACTIVE = 'config/CONFIG_COMPANIES_ACTIVE'
export const CONFIG_COMPANIES_ENABLED = 'config/CONFIG_COMPANIES_ENABLED'
export const CONFIG_RESET_PERSONALIZATION = 'config/CONFIG_RESET_PERSONALIZATION'
export const CONFIG_COMPANY_LOGO = 'config/CONFIG_COMPANY_LOGO'
export const CONFIG_UNIFIED_REGISTRATION = 'config/CONFIG_UNIFIED_REGISTRATION'

/**
 * Tipos da Store da Dashboard.
 */
export const DASH_LOADING = 'dash/DASH_LOADING'
export const DASH_DATES = 'dash/DASH_DATES'
export const DASH_START_DATE = 'dash/DASH_START_DATE'
export const DASH_END_DATE = 'dash/DASH_END_DATE'
/* Área de Usuários e Acessos */
export const DASH_ACTIVE_ENABLED_USERS = 'dash/DASH_ACTIVE_ENABLED_USERS'
export const DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD = 'dash/DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD'
export const DASH_ACESSES_IN_THE_PERIOD = 'dash/DASH_ACESSES_IN_THE_PERIOD'
export const DASH_USERS_PER_AGE = 'dash/DASH_USERS_PER_AGE'
export const DASH_INTERESTS_WITH_MOST_USERS = 'dash/DASH_INTERESTS_WITH_MOST_USERS'
export const DASH_USERS_PER_GENDER = 'dash/DASH_USERS_PER_GENDER'
export const DASH_USERS_PER_PLATFORM = 'dash/DASH_USERS_PER_PLATFORM'
export const DASH_USER_REGISTRATION_INFORMATION = 'dash/DASH_USER_REGISTRATION_INFORMATION'
export const DASH_CITIES_WITH_MOST_USERS = 'dash/DASH_CITIES_WITH_MOST_USERS'
export const DASH_USERS_PER_MOBILE_BRANDING = 'dash/DASH_USERS_PER_MOBILE_BRANDING'
export const DASH_COMMUNICATION_RESUME = '/dashDASH_COMMUNICATION_RESUME'

/* Área de Produtos Kaledo */
export const WALLET_BALANCE = 'dash-products/WALLET_BALANCE'
export const PRODUCTS_DASH_AWARDS_RESUME = 'dash-products/PRODUCTS_DASH_AWARDS_RESUME'
export const PRODUCTS_DASH_AWARDS_BAR_DATA = 'dash-products/PRODUCTS_DASH_AWARDS_BAR_DATA'
export const PRODUCTS_DASH_AWARDS_TABLE_DATA = 'dash-products/PRODUCTS_DASH_AWARDS_TABLE_DATA'
export const PRODUCTS_DASH_ENGAGEMENT_RESUME = 'dash-products/PRODUCTS_DASH_ENGAGEMENT_RESUME'
export const PRODUCTS_DASH_ENGAGEMENT_BAR_DATA = 'dash-products/PRODUCTS_DASH_ENGAGEMENT_BAR_DATA'
export const PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA = 'dash-products/PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA'
export const PRODUCTS_DASH_VIDALINK_RESUME = 'dash-products/PRODUCTS_DASH_VIDALINK_RESUME'
export const PRODUCTS_DASH_VIDALINK_BAR_DATA = 'dash-products/PRODUCTS_DASH_VIDALINK_BAR_DATA'

/**
 * Tipos da Store de Usuário.
 */

export const USER = 'user/USER'
export const USER_CLEAR = 'user/USER_CLEAR'
export const USER_COUNT = 'user/USER_COUNT'
export const USER_PATCH = 'user/USER_PATCH'
export const USER_DELETE = 'user/USER_DELETE'
export const USERS_CLEAR = 'user/USERS_CLEAR'
export const USERS_SEARCH = 'user/USERS_SEARCH'
export const USERS_PAGINATION = 'user/USERS_PAGINATION'
export const USER_TOGGLE_STATUS = 'user/USER_TOGGLE_STATUS'
export const USER_MANAGER_TOGGLE_STATUS = 'user/USER_MANAGER_TOGGLE_STATUS'
export const USERS_BULK_DELETE = 'user/USERS_BULK_DELETE'
export const USER_PANEL_ONBOARDING = 'user/USER_PANEL_ONBOARDING'
export const USER_BUY_NEW_BALANCE = 'user/USER_BUY_NEW_BALANCE'
export const USER_WALLET_ORDERS = 'user/USER_WALLET_ORDERS'
export const USER_WALLET_ORDER_DETAIL = 'user/USER_WALLET_ORDER_DETAIL'
export const USER_CANCEL_WALLET_ORDER = 'user/USER_CANCEL_WALLET_ORDER'

/**
 * Tipos da Store de Feedback.
 */

export const FEEDBACK = 'feedback/FEEDBACK'
export const FEEDBACK_CLOSE = 'feedback/FEEDBACK_CLOSE'

/**
 * Tipos da Store de Parceiros
 */

export const PARTNER = 'partner/PARTNER'
export const PARTNER_LIST = 'partner/PARTNER_LIST'
export const PARTNER_COUNT = 'partner/PARTNER_COUNT'
export const PARTNER_CREATE = 'partner/PARTNER_CREATE'
export const PARTNER_CLEAR = 'partner/PARTNER_CLEAR'
export const PARTNER_UPDATE = 'partner/PARTNER_UPDATE'
export const PARTNER_DELETE = 'partner/PARTNER_DELETE'
export const PARTNER_ADDRESSES = 'partner/PARTNER_ADDRESSES'
export const PARTNER_LIST_FILTER = 'partner/PARTNER_LIST_FILTER'
export const PARTNER_TOGGLE_STATUS = 'partner/PARTNER_TOGGLE_STATUS'
export const PARTNER_LIST_PAGINATION = 'partner/PARTNER_LIST_PAGINATION'

export const PARTNER_LOGO_UPLOAD = 'partner/PARTNER_LOGO_UPLOAD'
export const PARTNER_ADDRESS_UPLOAD = 'partner/PARTNER_ADDRESS_UPLOAD'

export const PARTNERS = 'partner/PARTNERS'
export const GET_PARTNER_CAMPAIGNS = 'partner/GET_PARTNER_CAMPAIGNS'
export const GET_ALL_PARTNERS_WITH_CAMPAIGNS = 'partner/GET_ALL_PARTNERS_WITH_CAMPAIGNS'
/**
 * Tipos da Store de Categories.
 */

export const CATEGORIES = 'categories/CATEGORIES'

/**
 * Tipos da Store de Campanhas.
 */

export const CAMPAIGN = 'campaign/CAMPAIGN'
export const CAMPAIGN_CLEAR = 'campaign/CAMPAIGN_CLEAR'
export const CAMPAIGN_CREATE = 'campaign/CAMPAIGN_CREATE'
export const CAMPAIGN_UPDATE = 'campaign/CAMPAIGN_UPDATE'
export const CAMPAIGN_DELETE = 'campaign/CAMPAIGN_DELETE'
export const CAMPAIGN_LIST_CLEAR = 'campaign/CAMPAIGN_LIST_CLEAR'
export const CAMPAIGN_LIST_FILTER = 'campaign/CAMPAIGN_LIST_FILTER'
export const CAMPAIGN_TOGGLE_STATUS = 'campaign/CAMPAIGN_TOGGLE_STATUS'
export const CAMPAIGN_LIST_PAGINATION = 'campaign/CAMPAIGN_LIST_PAGINATION'
export const CAMPAIGN_LIST_HIGHLIGHTED = 'campaign/CAMPAIGN_LIST_HIGHLIGHTED'
export const CAMPAIGN_SET_HIGHLIGHTED = 'campaign/CAMPAIGN_SET_HIGHLIGHTED'
export const CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW = 'campaign/CAMPAIGN_LIST_HIGHLIGHTED_PREVIEW'

/**
 * Tipos da Store de Empresas.
 */

export const COMPANY = 'company/COMPANY'
export const COMPANY_PERMISSIONS = 'company/COMPANY_PERMISSIONS'
export const COMPANY_SETTINGS = 'company/COMPANY_SETTINGS'
export const COMPANY_STYLE = 'company/COMPANY_STYLE'
export const COMPANY_STYLE_LOGIN = 'company/COMPANY_STYLE_LOGIN'
export const COMPANY_CALL_SALES = 'company/COMPANY_CALL_SALES'
export const COMPANY_TRIAL_CHECK = 'company/COMPANY_TRIAL_CHECK'
export const COMPANY_BALANCE = 'company/COMPANY_BALANCE'

/**
 * Tipos da store do breadcrumb.
 */

export const BREADCRUMB = 'breadcrumb/BREADCRUMB'
export const BREADCRUMB_NAMES = 'breadcrumb/BREADCRUMB_NAMES'
export const BREADCRUMB_ROUTES = 'breadcrumb/BREADCRUMB_ROUTES'
export const BREADCRUMB_CATEGORY = 'breadcrumb/BREADCRUMB_CATEGORY'

/**
 * Tipos da store do endereços.
 */

export const ADDRESS = 'address/ADDRESS'
export const ADDRESS_CLEAR = 'address/ADDRESS_CLEAR'
export const ADDRESS_FILTER = 'address/ADDRESS_FILTER'
export const ADDRESS_PAGINATE = 'address/ADDRESS_PAGINATE'

/**
 * Tipos da store relatórios
 */

export const REPORT = 'report/REPORT'
export const REPORT_LIST = 'report/REPORT_LIST'

/**
 * Tipos da store de importação de usuários
 */

export const USER_IMPORT_FILE = 'user-import/USER_IMPORT_FILE'
export const USER_IMPORT_LIST = 'user-import/USER_IMPORT_LIST'
export const USER_IMPORT_MANUAL = 'user-import/USER_IMPORT_MANUAL'
export const USER_IMPORT_CANCEL = 'user-import/USER_IMPORT_CANCEL'
export const USER_IMPORT_PAGINATION = 'user-import/USER_IMPORT_PAGINATION'
export const USER_IMPORT_PROCESSING = 'user-import/USER_IMPORT_PROCESSING'
export const USER_IMPORT_CONFIRMATION = 'user-import/USER_IMPORT_CONFIRMATION'
export const USER_IMPORT_DOWNLOAD_CPFS_ENABLED = 'user-import/USER_IMPORT_DOWNLOAD_CPFS_ENABLED'

/**
 * Tipos da store de mídias
 */

export const MEDIAS = 'medias/MEDIAS'
export const MEDIA = 'medias/MEDIA'
export const SUCCESS = 'medias/SUCCESS'
export const CREATE_MEDIA = 'medias/CREATE_MEDIA'
export const UPDATE_MEDIA = 'medias/UPDATE_MEDIA'
export const DELETE_MEDIA = 'medias/DELETE_MEDIA'
export const CAN_CREATE_BANNERS = 'medias/CAN_CREATE_BANNERS'

/**
 * Tipos da store de notificações
 */
export const NOTIFICATION = 'medias/NOTIFICATION'
export const NOTIFICATIONS = 'medias/NOTIFICATIONS'
export const GET_NOTIFICATIONS = 'medias/GET_NOTIFICATIONS'
export const GET_NOTIFICATION_DETAILS = 'medias/GET_NOTIFICATION_DETAILS'
export const CREATE_NOTIFICATION = 'medias/CREATE_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'medias/UPDATE_NOTIFICATION'
export const NOTIFICATION_CREATION_STEP = 'medias/NOTIFICATION_CREATION_STEP'

/**
 * Tipos da store de premiações
 */

export const AWARD_ORDER = 'awards/AWARD_ORDER'
export const ORDERS_LIST = 'awards/ORDERS_LIST'
export const AWARDS_GET_ORDERS_LIST = 'awards/AWARDS_GET_ORDERS_LIST'
export const CANCEL_ORDER = 'awards/CANCEL_ORDER'
export const AWARDS_CREATE_ORDER = 'awards/AWARDS_CREATE_ORDER'
export const GET_AWARDS_DETAILS = 'awards/GET_AWARDS_DETAILS'
export const GET_AWARDS_CAMPAIGNS = 'awards/GET_AWARDS_CAMPAIGNS'
export const AWARDS_CAMPAIGNS = 'awards/AWARDS_CAMPAIGNS'
export const AWARD_STEP = 'awards/AWARD_STEP'

/**
 * Tipos da store de engajamento
 */

// Aba Personalização
export const COIN = 'engagement-personalization/COIN'
export const GET_COIN = 'engagement-personalization/GET_COIN'
export const CREATE_COIN = 'engagement-personalization/CREATE_COIN'
export const UPDATE_COIN = 'engagement-personalization/UPDATE_COIN'
export const CATALOG = 'engagement-personalization/CATALOG'
export const GET_CATALOG = 'engagement-personalization/GET_CATALOG'
export const CATALOG_PAGINATION = 'engagement-personalization/CATALOG_PAGINATION'
export const TOGGLE_CATALOG_STATUS = 'engagement-personalization/TOGGLE_CATALOG_STATUS'

// Aba Campanhas
export const CREATE_ENGAGEMENT_CAMPAIGN = 'engagement/CREATE_ENGAGEMENT_CAMPAIGN'
export const UPDATE_ENGAGEMENT_CAMPAIGN = 'engagement/UPDATE_ENGAGEMENT_CAMPAIGN'
export const GET_ENGAGEMENT_CAMPAIGN_DETAILS = 'engagement/GET_ENGAGEMENT_CAMPAIGN_DETAILS'
export const GET_ENGAGEMENT_CAMPAIGNS = 'engagement/GET_ENGAGEMENT_CAMPAIGNS'
export const GET_ENGAGEMENT_CAMPAIGNS_IMAGES = 'engagement/GET_ENGAGEMENT_CAMPAIGNS_IMAGES'
export const ENGAGEMENT_CAMPAIGNS = 'engagement/ENGAGEMENT_CAMPAIGNS'
export const ENGAGEMENT_CAMPAIGNS_IMAGES = 'engagement/ENGAGEMENT_CAMPAIGNS_IMAGES'
export const ENGAGEMENT_CAMPAIGNS_STEP = 'engagement/ENGAGEMENT_CAMPAIGNS_STEP'

// Aba Distribuição
export const GET_DISTRIBUTION_ORDERS = 'engagement/GET_DISTRIBUTION_ORDERS'
export const GET_DISTRIBUTION_ORDER_DETAILS = 'engagement/GET_DISTRIBUTION_ORDER_DETAILS'
export const CANCEL_DISTRIBUTION_ORDER = 'engagement/CANCEL_DISTRIBUTION_ORDER'
export const CREATE_DISTRIBUTION_ORDER = 'engagement/CREATE_DISTRIBUTION_ORDER'
export const UPDATE_DISTRIBUTION_ORDER = 'engagement/UPDATE_DISTRIBUTION_ORDER'
export const DISTRIBUTION_ORDER = 'engagement/DISTRIBUTION_ORDER'
export const DISTRIBUTION_ORDERS = 'engagement/DISTRIBUTION_ORDERS'
export const DISTRIBUTION_STEP = 'engagement/DISTRIBUTION_STEP'
