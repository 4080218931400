import * as types from '@/store/types'
import moment from 'moment'
import rest from '@/modules/rest'

const state = {
  loading: false,
  start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  end_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  walletBalance: 0,
  awardsResume: {},
  awardsBarData: {},
  awardsTableData: {},
  engagementResume: {},
  engagementBarData: {},
  engagementTableData: {},
  vidalinkResume: {},
  vidalinkBarData: {}
}

const getters = {
  [types.WALLET_BALANCE]: ({ walletBalance }) => walletBalance,
  [types.PRODUCTS_DASH_AWARDS_RESUME]: ({ awardsResume }) => (awardsResume),
  [types.PRODUCTS_DASH_AWARDS_BAR_DATA]: ({ awardsBarData }) => (awardsBarData),
  [types.PRODUCTS_DASH_AWARDS_TABLE_DATA]: ({ awardsTableData }) => (awardsTableData),
  [types.PRODUCTS_DASH_ENGAGEMENT_RESUME]: ({ engagementResume }) => (engagementResume),
  [types.PRODUCTS_DASH_ENGAGEMENT_BAR_DATA]: ({ engagementBarData }) => (engagementBarData),
  [types.PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA]: ({ engagementTableData }) => (engagementTableData),
  [types.PRODUCTS_DASH_VIDALINK_RESUME]: ({ vidalinkResume }) => (vidalinkResume),
  [types.PRODUCTS_DASH_VIDALINK_BAR_DATA]: ({ vidalinkBarData }) => (vidalinkBarData)
}

const mutations = {
  [types.WALLET_BALANCE]: (state, payload) => {
    state.walletBalance = payload
  },
  [types.DASH_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.DASH_DATES]: (state, payload) => {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  },
  [types.PRODUCTS_DASH_AWARDS_RESUME]: (state, payload) => {
    state.awardsResume = payload
  },
  [types.PRODUCTS_DASH_AWARDS_BAR_DATA]: (state, payload) => {
    state.awardsBarData = payload
  },
  [types.PRODUCTS_DASH_AWARDS_TABLE_DATA]: (state, payload) => {
    state.awardsTableData = payload
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_RESUME]: (state, payload) => {
    state.engagementResume = payload
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_BAR_DATA]: (state, payload) => {
    state.engagementBarData = payload
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA]: (state, payload) => {
    state.engagementTableData = payload
  },
  [types.PRODUCTS_DASH_VIDALINK_RESUME]: (state, payload) => {
    state.vidalinkResume = payload
  },
  [types.PRODUCTS_DASH_VIDALINK_BAR_DATA]: (state, payload) => {
    state.vidalinkBarData = payload
  }
}

const actions = {
  [types.WALLET_BALANCE]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const endPoint = `company/${companyId}/dashboard/balance`

    try {
      const res = await rest().get(endPoint)
      if (res && res.data && res.data.data) {
        if (res.data.data.balance) {
          commit(types.WALLET_BALANCE, res.data.data.balance)
          return {
            data: res.data.data.balance,
            success: true
          }
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o saldo, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o saldo, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_AWARDS_RESUME]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/awardsResume?start_date=${startDate}&end_date=${endDate}`

    try {
      const res = await rest().get(endPoint)
      if (res && res.data && res.data.data) {
        commit(types.PRODUCTS_DASH_AWARDS_RESUME, res.data.data)
        return {
          data: res.data.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o resumo de prêmios, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o resumo de prêmios, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_AWARDS_BAR_DATA]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/awardsDistributed?start_date=${startDate}&end_date=${endDate}`
    try {
      const res = await rest().get(endPoint)
      if (res && res.data) {
        commit(types.PRODUCTS_DASH_AWARDS_BAR_DATA, res.data.data)
        return {
          data: res.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o gráfico de prêmios, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o gráfico de prêmios, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_AWARDS_TABLE_DATA]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/awardsCampaignsRescued?start_date=${startDate}&end_date=${endDate}`
    try {
      const res = await rest().get(endPoint)
      if (res && res.data) {
        commit(types.PRODUCTS_DASH_AWARDS_TABLE_DATA, res.data.data)
        return {
          data: res.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter a tabela de prêmios, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter a tabela de prêmios, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_RESUME]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/engagementsResume?start_date=${startDate}&end_date=${endDate}`

    try {
      const res = await rest().get(endPoint)
      if (res && res.data && res.data.data) {
        commit(types.PRODUCTS_DASH_ENGAGEMENT_RESUME, res.data.data)
        return {
          data: res.data.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o resumo de pontos, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter o resumo de pontos, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_BAR_DATA]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/engagementsDistributed?start_date=${startDate}&end_date=${endDate}`
    try {
      const res = await rest().get(endPoint)
      if (res && res.data) {
        commit(types.PRODUCTS_DASH_ENGAGEMENT_BAR_DATA, res.data.data)
        return {
          data: res.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os gráficos de pontos, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os gráficos de pontos, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/engagementsCampaignsRescued?start_date=${startDate}&end_date=${endDate}`
    try {
      const res = await rest().get(endPoint)
      if (res && res.data) {
        commit(types.PRODUCTS_DASH_ENGAGEMENT_TABLE_DATA, res.data.data)
        return {
          data: res.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter a tabela de pontos, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter a tabela de pontos, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_VIDALINK_RESUME]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/vidalinkResume?start_date=${startDate}&end_date=${endDate}`

    try {
      const res = await rest().get(endPoint)
      if (res && res.data && res.data.data) {
        commit(types.PRODUCTS_DASH_VIDALINK_RESUME, res.data.data)
        return {
          data: res.data.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os resumo do vidalink, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os resumo do vidalink, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },
  [types.PRODUCTS_DASH_VIDALINK_BAR_DATA]: async ({ commit, getters, dispatch }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/vidalinkCardsActivatedByMonth?start_date=${startDate}&end_date=${endDate}`
    try {
      const res = await rest().get(endPoint)

      if (res && res.data) {
        commit(types.PRODUCTS_DASH_VIDALINK_BAR_DATA, res.data.data)
        return {
          data: res.data,
          success: true
        }
      } else {
        dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os gráficos do vidalink, por favor, tente novamente mais tarde' })
        return {
          data: null,
          success: false
        }
      }
    } catch (error) {
      dispatch(types.FEEDBACK, { type: 'error', text: 'Houve um erro ao obter os gráficos do vidalink, por favor, tente novamente mais tarde' })
      return {
        data: null,
        success: false
      }
    }
  },

  [types.DASH_ACTIVE_ENABLED_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const endPoint = `company/${companyId}/dashboard/totalOfEnabledAndActivatedUsers`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACTIVE_ENABLED_USERS, res.data.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/totalOfUsersSpineGraph?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_ACESSES_IN_THE_PERIOD]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/accessesInThePeriod?start_date=${startDate}&end_date=${endDate}`
    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_ACESSES_IN_THE_PERIOD, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_AGE]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerAge?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_AGE, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_INTERESTS_WITH_MOST_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/interestsWithMostUsers?start_date=${startDate}&end_date=${endDate}`
    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_INTERESTS_WITH_MOST_USERS, {
          ...res.data
        })
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_GENDER]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerGender?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_GENDER, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },

  [types.DASH_USERS_PER_PLATFORM]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerPlatform?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_PLATFORM, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USER_REGISTRATION_INFORMATION]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/userRegistrationInformation?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USER_REGISTRATION_INFORMATION, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_CITIES_WITH_MOST_USERS]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/citiesWithMostUsers?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_CITIES_WITH_MOST_USERS, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  },
  [types.DASH_USERS_PER_MOBILE_BRANDING]: async ({ commit, getters }) => {
    const companyId = getters[types.CONFIG_COMPANY_ID]
    const startDate = getters[types.DASH_START_DATE]
    const endDate = getters[types.DASH_END_DATE]
    const endPoint = `company/${companyId}/dashboard/usersPerMobileBranding?start_date=${startDate}&end_date=${endDate}`

    try {
      await rest().get(endPoint).then(res => {
        commit(types.DASH_USERS_PER_MOBILE_BRANDING, res.data)
      })

      return true
    } catch (error) {
      return false
    }
  }
}

export default { state, getters, mutations, actions }
